import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="summary-report"
export default class extends Controller {
  connect() {
    $('#reports-table').show()
    $('#csv-from_filter').val($('#from-filter').val())
    $('#csv-to_filter').val($('#to-filter').val())
    $('#csv-store_id').val($('#store_id').val())
    $('#csv-account_type_id').val($('#account_type_id').val())
    $('#csv-lending_campaign').val($('#lending_campaign').val())
    $('#csv-program_types').val($('#all_program_types').val())

    $('#from-filter, #to-filter').change(function () {
      if ($(this).val() != '') {
        $(this).removeClass('error')
        $(this).next().remove()
      }
    });


    $(".fromDate").datepicker({
      format: 'mm-dd-yyyy',
      autoclose: true,
    }).on('changeDate', function (selected) {
      var minDate = new Date(selected.date.valueOf());
      $('.toDate').datepicker('setStartDate', minDate);
      // var minDate = new Date(selected.date.valueOf());
      // var maxDate = new Date(minDate);
      // maxDate.setMonth(maxDate.getMonth() + 2);
      // $('.toDate').datepicker('setStartDate', minDate);
      // $('.toDate').datepicker('setEndDate', maxDate);
    });

    $(".toDate").datepicker({
      format: 'mm-dd-yyyy',
      autoclose: true,
    }).on('changeDate', function (selected) {
      var minDate = new Date(selected.date.valueOf());
      $('.fromDate').datepicker('setEndDate', minDate);
    });

    $('#lender_filter_ids').select2({
      placeholder: "All"
    });

    if (!$('#prescreen_inactive_member').is(':checked')) {
      $('#deactive_member').hide();
    }
    $('.sublink-field input').prop('checked', false)

    $("select#store_id").change(function () {
      if (this.value.length > 0) {
        $('#sub_link').attr("disabled", false)
      } else {
        $('#sub_link').attr("disabled", true)
        $('.sublink-field input').prop('checked', false)
      }
    });

    $('#prescreen_OFF, #prescreen_ON, #prescreen_inactive_member').on('click', function () {
      if ($('#prescreen_inactive_member').is(':checked')) {
        $('#to-filter').attr('required', false)
        $('#from-filter').attr('required', false)
        $('#deactive_member').show();
        $('#prescreen_active_member').hide()
      } else {
        $('#to-filter').attr('required', true)
        $('#from-filter').attr('required', true)
        $('#deactive_member').hide();
        $('#prescreen_active_member').show()
      }
    });

    $('#prescreen_OFF, #prescreen_ON, #prescreen_inactive_member').on('change', function () {
      $('#reports-table').hide()
    });

    $('#prescreen_OFF').on('change', function () {
      if ($(this).is(':checked')) {
        $("#all_program_types option").remove();
        $('#all_program_types').append('<option value="">All</option>')
        $.each($(this).data('all-program-types'), function (index, item) {
          $("#all_program_types").append(`<option value=${item[1]}>${item[0]}</option>`);
        });
      }
    });

    $('#prescreen_ON').on('change', function () {
      if ($(this).is(':checked')) {
        $("#all_program_types option").remove();
        $('#all_program_types').append('<option value="">All</option>')
        $.each($(this).data('program-types'), function (index, item) {
          $("#all_program_types").append(`<option value=${item[1]}>${item[0]}</option>`);
        });
      }
    });
  }

  update_filter(event) {
    var program_type = event.target.value
    var user = $(event.target).data('user')
    var url;

    console.log($(event.target).data('user'))

    if (program_type == '') {
      program_type = 'all'
    }

    if (user == 'Member') {
      url = `/member/reports/${program_type}/program_type_filter`
    } else {
      url = `/admin/reports/${program_type}/program_type_filter`
    }

    $.ajax({
      type: "get",
      url: url,
      data: {},
      contentType: "application/json",
      dataType: "json",
      success: function (res) {
        // $("#access_token").text(res['access_token']);
        $("#lender_filter_ids option").remove();
        $("#store_id option").remove()
        $.each(res.lenders, function (index, item) {
          $("#lender_filter_ids").append(`<option value=${item[1]}>${item[0]}</option>`);
        });
        $('#store_id').append('<option value="">All</option>')
        $.each(res.stores, function (index, item) {
          $("#store_id").append(`<option value=${item[1]}>${item[0]}</option>`);
        });
      }
    });
  }

  submitForm() {
    $('#summary_report_id').valid();
  }
}
